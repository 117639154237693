import axios from "axios"
import Endpoints from "../environments/endpoints"
import { PaginatedGiveaway } from "../types/GiveawayTypes"

const getGiveawayLeaderboard = (
    giveawayId: number,
    page: number,
    pageSize: number,
    onSuccess: (leaderboard: PaginatedGiveaway) => void
) => {
    axios
        .get(`${Endpoints.apiEndpoint}/giveaway/${giveawayId}/leaderboard?page=${page}&pageSize=${pageSize}`)
        .then(response => {
            onSuccess(response.data)
        })
        .catch(error => {})
}

export default { getGiveawayLeaderboard }
