import React, { useState, useEffect, useRef } from "react"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ArrowButton from "../ArrowButton/ArrowButton"
import Styles from "./HorizontalScroll.style"

interface HorizontalScrollProps {
    items: JSX.Element[]
    secondRowItems?: JSX.Element[]
    sameItemsButDoubleRow?: boolean
    whiteArrows?: boolean
}

const CARDS_MARGIN = 12
const MIN_NUMBER_FOR_DOUBLE_ROW = 10

const HorizontalScroll: React.FC<HorizontalScrollProps> = props => {
    const theme = useTheme()
    const matchesMdAndWider = useMediaQuery(theme.breakpoints.up("md"))

    const MIN_LEFT_POSITION = 0
    const MAX_RIGHT_POSITION = useRef<number>(-1)
    const CONTAINER = useRef<HTMLDivElement>(null)
    const CONTAINER_WIDTH = useRef<number>(0)

    const [position, setPosition] = useState(0)
    const [arrowsEnabled, enableArrows] = useState(false)

    const styles = Styles({})

    useEffect(() => {
        function enableActions() {
            if (CONTAINER_WIDTH.current > window.innerWidth) {
                enableArrows(true)
            }
        }
        if (CONTAINER && CONTAINER.current) {
            MAX_RIGHT_POSITION.current = window.innerWidth - CONTAINER.current.offsetWidth - CARDS_MARGIN
            CONTAINER_WIDTH.current = CONTAINER.current.offsetWidth
            enableActions()
        }
    })

    let upperItems: JSX.Element[] = []
    let lowerItems: JSX.Element[] = []

    if (props.secondRowItems === undefined) {
        if (props.sameItemsButDoubleRow && props.items.length > MIN_NUMBER_FOR_DOUBLE_ROW) {
            props.items.forEach((item, index) => {
                if (index % 2) {
                    lowerItems.push(item)
                } else {
                    upperItems.push(item)
                }
            })
        } else {
            upperItems = props.items
        }
    } else {
        upperItems = props.items
        lowerItems = props.secondRowItems
    }

    const calculateBounds = (position: number) => {
        //MAX_RIGHT_POSITION is negative
        if (position < MAX_RIGHT_POSITION.current) {
            return MAX_RIGHT_POSITION.current
        }
        if (position > MIN_LEFT_POSITION) {
            return MIN_LEFT_POSITION
        }
        return position
    }

    const goLeft = () => {
        const newPosition = position + window.innerWidth / 2
        setPosition(calculateBounds(newPosition))
    }

    const goRight = () => {
        const newPosition = position - window.innerWidth / 2
        setPosition(calculateBounds(newPosition))
    }

    const renderRow = (items: JSX.Element[]) => (
        <div className={styles.horizontalContainer}>
            {items.map((item, index) => (
                <div key={index}>{item}</div>
            ))}
        </div>
    )

    const widerScreensComponent = (
        <div ref={CONTAINER} className={styles.container} style={{ transform: "translateX(" + position + "px)" }}>
            {renderRow(upperItems)}
            {lowerItems.length > 0 && renderRow(lowerItems)}
        </div>
    )

    const narrowerScreensComponent = (
        <>
            {renderRow(upperItems)}
            {lowerItems.length > 0 && renderRow(lowerItems)}
        </>
    )

    return (
        <div className={!matchesMdAndWider ? styles.scrollableContainer : undefined}>
            {matchesMdAndWider ? widerScreensComponent : narrowerScreensComponent}
            {matchesMdAndWider && (
                <div className={styles.arrowContainer}>
                    <ArrowButton
                        direction="left"
                        white={props.whiteArrows}
                        disabled={position === MIN_LEFT_POSITION || !arrowsEnabled}
                        onClick={goLeft}
                    />
                    <ArrowButton
                        direction="right"
                        white={props.whiteArrows}
                        disabled={position === MAX_RIGHT_POSITION.current || !arrowsEnabled}
                        onClick={goRight}
                    />
                </div>
            )}
        </div>
    )
}

export default HorizontalScroll
