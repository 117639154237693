import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../utils/FontWeights"
import Colors from "../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 8
        },
        authorContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
                alignItems: "flex-start"
            }
        },
        mathQuestionContainer: {
            overflow: "auto"
        },
        questionImage: {
            width: "100%"
        }
    })
)

const paperStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "32px 28px",
            margin: "10px",
            [theme.breakpoints.down("xs")]: {
                padding: "24px 16px"
            }
        }
    })
)

const authorTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 18,
            color: Colors.questionAuthorFont,
            marginRight: 8,
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
                fontSize: 16,
                marginBottom: 6
            }
        }
    })
)

const questionTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 24,
            fontWeight: FontWeights.BOLD,
            color: Colors.questionQuestionFont,
            marginBottom: 12,
            [theme.breakpoints.down("xs")]: {
                fontSize: 20
            }
        }
    })
)

const avatarStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 28,
            color: Colors.primary,
            marginRight: 6,
            width: 28,
            height: 28
        }
    })
)

export default {
    styles,
    paperStyle,
    authorTypoStyle,
    questionTypoStyle,
    avatarStyle
}
