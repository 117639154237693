import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../../utils/FontWeights"
import { CSSProperties } from "@material-ui/styles"
import Colors from "../../../utils/Colors"
import { AnswerCorrectness } from "../../../types/AnswerTypes"

const styles = (correctness: AnswerCorrectness) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            mathInputContainer: {
                display: "flex",
                width: "100%",
                alignSelf: "center",
                color: correctness === "neutral" ? Colors.answerText : theme.palette.common.white
            }
        })
    )
}

const buttonStyle = (correctness: AnswerCorrectness) => {
    let style: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "8px 12px 8px 12px",
        textTransform: "none"
    }
    if (correctness === "neutral") {
        return makeStyles((theme: Theme) => {
            style.border = `1px solid ${Colors.answerNeutralBorder}`
            style[theme.breakpoints.down("xs")] = {
                marginTop: 8,
                marginBottom: 8
            }
            return createStyles({
                root: style
            })
        })
    }
    if (correctness === "correct") {
        return makeStyles((theme: Theme) => {
            style.border = `1px solid ${Colors.answerCorrect}`
            style.backgroundColor = Colors.answerCorrect
            style["&:hover"] = {
                backgroundColor: `${Colors.answerCorrect}!important`
            }
            style[theme.breakpoints.down("xs")] = {
                marginTop: 8,
                marginBottom: 8
            }
            return createStyles({
                root: style
            })
        })
    }
    return makeStyles((theme: Theme) => {
        style.border = `1px solid ${Colors.answerWrong}`
        style.backgroundColor = Colors.answerWrong
        style["&:hover"] = {
            backgroundColor: `${Colors.answerWrong}!important`
        }
        style[theme.breakpoints.down("xs")] = {
            marginTop: 8,
            marginBottom: 8
        }
        return createStyles({
            root: style
        })
    })
}

const answerLetterTypoStyle = (correctness: AnswerCorrectness) => {
    return makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                fontSize: 24,
                fontWeight: FontWeights.BOLD,
                paddingRight: 10,
                color: correctness === "neutral" ? Colors.answerLetter : theme.palette.common.white
            }
        })
    })
}

const answerTypoStyle = (correctness: AnswerCorrectness) => {
    return makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                fontSize: 20,
                fontWeight: FontWeights.BOLD,
                paddingLeft: 10,
                textAlign: "left",
                alignSelf: "center",
                color: correctness === "neutral" ? Colors.answerText : theme.palette.common.white
            }
        })
    })
}

export default {
    styles,
    buttonStyle,
    answerLetterTypoStyle,
    answerTypoStyle
}
