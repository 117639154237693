import React from "react"
import IconButton from "@material-ui/core/IconButton"
import LeftArrow from "@material-ui/icons/KeyboardArrowLeftRounded"
import RightArrow from "@material-ui/icons/KeyboardArrowRightRounded"
import Styles from "./ArrowButton.style"

interface ArrowButtonProps {
    direction: "left" | "right"
    white?: boolean
    disabled?: boolean
    onClick(): void
}

const ArrowButton: React.FC<ArrowButtonProps> = props => {
    const iconButtonStyle = Styles.iconButtonStyle(props.white)({})
    const arrowStyle = Styles.arrowStyle({})

    const leftArrow = <LeftArrow classes={arrowStyle} />
    const rightArrow = <RightArrow classes={arrowStyle} />

    return (
        <IconButton
            aria-label={props.direction}
            disabled={props.disabled}
            classes={iconButtonStyle}
            onClick={props.onClick}
        >
            {props.direction === "left" ? leftArrow : rightArrow}
        </IconButton>
    )
}

export default ArrowButton
