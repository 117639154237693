import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%"
        },
        narrowContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    })
)

const dialogStyle = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: "unset",
            boxShadow: "unset",
            overflow: "hidden"
        }
    })
)

const circularProgressStyle = (white?: boolean) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            root: {
                color: white ? theme.palette.common.white : theme.palette.primary.main
            }
        })
    )
}

export default {
    styles,
    dialogStyle,
    circularProgressStyle
}
