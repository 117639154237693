import React from "react"
import Typography from "@material-ui/core/Typography"
import Styles from "./EmbeddedTopicSubtopicInfo.style"
import { MinimalTopicTypeResponse } from "../../../types/TopicTypes"
import { MinimalSubtopicTypeResponse } from "../../../types/SubtopicTypes"

interface EmbeddedTopicSubtopicInfoProps {
    topic: MinimalTopicTypeResponse
    subtopic: MinimalSubtopicTypeResponse
}

const EmbeddedTopicSubtopicInfo: React.FC<EmbeddedTopicSubtopicInfoProps> = props => {
    const styles = Styles.styles({})
    const topicTypoStyle = Styles.topicTypoStyle({})
    const subtopicTypoStyle = Styles.subtopicTypoStyle({})

    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <Typography classes={topicTypoStyle}>{`#${props.topic.name}`}</Typography>
            </div>
            <div className={styles.dot} />
            <div className={styles.textContainer}>
                <Typography classes={subtopicTypoStyle}>{`#${props.subtopic.name}`}</Typography>
            </div>
        </div>
    )
}

export default EmbeddedTopicSubtopicInfo
