import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Colors from "../../../utils/Colors"

const iconButtonStyle = (white?: boolean) => {
    return makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                padding: "unset",
                color: white ? theme.palette.common.white : theme.palette.secondary.main
            },
            disabled: {
                color: Colors.arrowDisabled
            }
        })
    })
}

const arrowStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: "3rem"
        }
    })
)

export default {
    iconButtonStyle,
    arrowStyle
}
