import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Colors from "../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: 600,
            minWidth: 280
        },
        headerContainer: {
            backgroundColor: Colors.primary,
            padding: 12
        },
        titleContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        },
        columnHeaderContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderTop: `1px solid ${Colors.white}`,
            marginLeft: -12,
            marginRight: -12,
            paddingTop: 12,
            paddingLeft: 12,
            paddingRight: 12
        },
        listItemContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 12,
            borderBottom: `1px solid ${Colors.questionCardAnsweredQuestion}`
        },
        usersFullname: {
            flexGrow: 1,
            fontSize: 14,
            fontWeight: "bold",
            color: Colors.primary,
            marginLeft: 12,
            marginRight: 12,
            display: "flex",
            alignItems: "center"
        },
        ordinalNumberFirstPlace: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 12,
            marginRight: 12,
            width: 24,
            height: 24,
            backgroundColor: Colors.primary,
            transform: "rotate(-45deg)",
            borderRadius: 2
        },
        ordinalNumberOthers: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 12,
            marginRight: 12,
            width: 24,
            height: 24,
            border: `1px solid ${Colors.likeInfoIcon}`,
            transform: "rotate(-45deg)",
            borderRadius: 2
        }
    })
)

const titleStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 18,
            fontWeight: "bold",
            color: Colors.white,
            marginBottom: 4
        }
    })
)

const subtitleStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            fontWeight: "bold",
            color: Colors.white
        }
    })
)

const textStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 14,
            color: Colors.white
        }
    })
)

const columnHeaderStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 12,
            fontWeight: "bold",
            color: Colors.white
        }
    })
)

const avatarStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 28,
            color: Colors.primary,
            marginRight: 6,
            width: 28,
            height: 28
        }
    })
)

const ordinalNumberFirstPlace = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            fontWeight: "bold",
            color: Colors.white,
            transform: "rotate(45deg)"
        }
    })
)

const ordinalNumberOthers = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            fontWeight: "bold",
            color: Colors.likeInfoIcon,
            transform: "rotate(45deg)"
        }
    })
)

const valueTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 18,
            fontWeight: "bold",
            color: Colors.secondary
        }
    })
)

export default {
    styles,
    titleStyle,
    subtitleStyle,
    textStyle,
    columnHeaderStyle,
    avatarStyle,
    ordinalNumberFirstPlace,
    ordinalNumberOthers,
    valueTypoStyle
}
