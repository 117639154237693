import React from "react"
import LikeIcon from "@material-ui/icons/ThumbUpRounded"
import Typography from "@material-ui/core/Typography"
import Styles from "./LikeInfo.style"

interface LikeInfoProps {
    numberOfLikes: number
}

const LikeInfo: React.FC<LikeInfoProps> = props => {
    const containerStyle = Styles.containerStyle({})
    const iconStyle = Styles.iconStyle({})
    const typoStyle = Styles.typoStyle({})

    return (
        <div className={containerStyle.container}>
            <LikeIcon classes={iconStyle} />
            <Typography classes={typoStyle} noWrap>
                {props.numberOfLikes}
            </Typography>
        </div>
    )
}

export default LikeInfo
