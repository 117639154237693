import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../utils/FontWeights"
import BackgroundImage from "../../images/icons-background-question-section.svg"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            overflow: "hidden",
            paddingTop: 28,
            paddingBottom: 28,
            backgroundSize: "contain",
            background: `url(${BackgroundImage}) left bottom no-repeat`,
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down("xs")]: {
                paddingTop: 20,
                paddingBottom: 8
            }
        },
        loadingContainer: {
            height: 286,
            [theme.breakpoints.down("sm")]: {
                height: 254
            }
        }
    })
)

const typoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: "center",
            fontSize: 28,
            fontWeight: FontWeights.BOLD,
            color: theme.palette.common.white,
            marginBottom: 20,
            [theme.breakpoints.down("xs")]: {
                fontSize: 20,
                marginBottom: 8
            }
        }
    })
)

export default {
    styles,
    typoStyle
}
