import React from "react"
import Button from "@material-ui/core/Button"
import FollowIcon from "@material-ui/icons/PersonAddRounded"
import CheckIcon from "@material-ui/icons/CheckCircleRounded"
import Typography from "@material-ui/core/Typography"
import Styles from "./FollowButton.style"
import { formatNumberOfFollowers } from "../../../utils/FollowersFormatters"

interface FollowButtonProps {
    following: boolean
    numberOfFollowers: number
    onFollow: () => void
    onSeeFollowers: () => void
    fullWidth?: boolean
    disabled?: boolean
    hideNumber?: boolean
}

const FollowButton: React.FC<FollowButtonProps> = props => {
    const styles = Styles.styles()
    const buttonStyle = Styles.buttonStyle(props.fullWidth)({})
    const numberButtonStyle = Styles.numberButtonStyle({})
    const iconStyle = Styles.iconStyle(props.following)({})
    const typoStyle = Styles.typoStyle(props.following)({})
    const numberTypoStyle = Styles.numberTypoStyle({})

    const invokeCallback = (callback: () => void) => (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        callback()
    }

    return (
        <div className={styles.container}>
            <Button
                variant={props.following ? "contained" : "outlined"}
                color="secondary"
                size="small"
                classes={buttonStyle}
                onClick={invokeCallback(props.onFollow)}
                component={"span"}
                disabled={props.disabled}
            >
                {props.following ? (
                    <>
                        <CheckIcon classes={iconStyle} />
                        <Typography classes={typoStyle} noWrap>
                            {"pratim"}
                        </Typography>
                    </>
                ) : (
                    <>
                        <FollowIcon classes={iconStyle} />
                        <Typography classes={typoStyle} noWrap>
                            {"prati"}
                        </Typography>
                    </>
                )}
            </Button>
            {!props.hideNumber && (
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    classes={numberButtonStyle}
                    onClick={invokeCallback(props.onSeeFollowers)}
                    component={"span"}
                    disabled={props.disabled}
                >
                    <Typography classes={numberTypoStyle} noWrap>
                        {formatNumberOfFollowers(props.numberOfFollowers)}
                    </Typography>
                </Button>
            )}
        </div>
    )
}

export default FollowButton
