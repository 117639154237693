// const API_URL = "https://staging.3topia.agency/quizapp-backend"
// const API_PORT = 8080
// const APP_URL = "http://localhost"
// const APP_PORT = 3001
const API_URL = "https://kvizovi.srednja.hr"
const API_PORT = 8080
const APP_URL = "https://kvizovi.srednja.hr"
const APP_PORT = 3000

const Endpoints = {
    // apiEndpoint: `${API_URL}`,
    // appEndpoint: `${APP_URL}:${APP_PORT}`,
    apiEndpoint: `${API_URL}:${API_PORT}`,
    appEndpoint: `${APP_URL}`,
    appEndpoints: {
        profile: "/profile/:fullName/:id",
        questionAnswering: "/question/:topicName/:subtopicName/:questionid",
        embeddedQuestion: "/embeddedquestion/:questionid",
        embeddedPopularQuestions: "/embeddedpopularquestions",
        embeddedGiveaway: "/embeddedgiveaway",
        embeddedGiveawayMaturanti: "/embeddedgiveaway-maturanti",
        embeddedGiveawayCarnet: "/embeddedgiveaway-carnet",
        embeddedGiveawayEUPovijestGeografija: "/embeddedgiveaway-eu-povijest-geografija",
        embeddedGiveawayEUOkolis: "/embeddedgiveaway-eu-okolis",
        embeddedGiveawayEUZnanost: "/embeddedgiveaway-eu-znanost",
        embeddedGiveawayEUUnija: "/embeddedgiveaway-eu-unija"
    }
}

export default Endpoints
