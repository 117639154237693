import React from "react"
import { AnswersHolderProps } from "../../../types/AnswerTypes"
import AnswerText from "../AnswerText/AnswerText"
import AnswerImage from "../AnswerImage/AnswerImage"
import Styles from "./AnswersGrid.style"

interface AnswersGridProps extends AnswersHolderProps {
    type: "text" | "image"
}

const AnswersGrid: React.FC<AnswersGridProps> = props => {
    const styles = Styles.styles({})

    const renderAnswerElement = (index: number) => {
        return props.type === "text" ? (
            <AnswerText
                answerLetter={props.answers[index].answerLetter}
                answer={props.answers[index].answer}
                correct="neutral"
                disabled={props.clickedAnswer !== undefined}
                onAnswer={props.onAnswer}
            />
        ) : (
            <AnswerImage
                answerLetter={props.answers[index].answerLetter}
                answer={props.answers[index].answer}
                correct="neutral"
                disabled={props.clickedAnswer !== undefined}
                onAnswer={props.onAnswer}
            />
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.leftAnswerContainer}>{renderAnswerElement(0)}</div>
                <div className={styles.rightAnswerContainer}>{renderAnswerElement(1)}</div>
            </div>
            <div className={styles.row}>
                <div className={styles.leftAnswerContainer}>{renderAnswerElement(2)}</div>
                <div className={styles.rightAnswerContainer}>{renderAnswerElement(3)}</div>
            </div>
        </div>
    )
}

export default AnswersGrid
