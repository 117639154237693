import React, { useState, useEffect } from "react"
import { withRouter } from "react-router"
import Styles from "./CarnetGiveaway.style"
import Endpoints from "../../environments/endpoints"
import Avatar from "@material-ui/core/Avatar"
import ProfileIcon from "@material-ui/icons/AccountCircleRounded"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import GiveawayDataAccess from "../../data_access/GiveawayDataAccess"
import { PaginatedGiveaway } from "../../types/GiveawayTypes"
import { MinimalUserTypeResponse } from "../../types/UserTypes"
import { getProfileURL } from "../../utils/BuildUrls"
import Illustration from "../../images/illustration-knowledge.svg"
import PaginationComponent from "../common_components/PaginationComponent/PaginationComponent"
import { GIVEAWAY_PAGESIZE } from "../EmbeddedGiveaway/EmbeddedGiveaway"

const CarnetGiveaway: React.FC = () => {
    const styles = Styles.styles({})
    const titleStyle = Styles.titleStyle({})
    const subtitleStyle = Styles.subtitleStyle({})
    const textStyle = Styles.textStyle({})
    const columnHeaderStyle = Styles.columnHeaderStyle({})
    const avatarStyle = Styles.avatarStyle({})
    const ordinalNumberFirstPlace = Styles.ordinalNumberFirstPlace({})
    const ordinalNumberOthers = Styles.ordinalNumberOthers({})
    const valueTypoStyle = Styles.valueTypoStyle({})

    const [leaderboard, setLeaderboard] = useState<PaginatedGiveaway>()
    const [pageState, setPageState] = useState({
        page: 0,
        rowsPerPage: GIVEAWAY_PAGESIZE
    })

    const composeUrl = (url: string) => Endpoints.appEndpoint + url

    const onSuccess = (page: number) => (list: PaginatedGiveaway) => {
        setPageState({ ...pageState, page })
        setLeaderboard(list)
    }

    useEffect(() => {
        GiveawayDataAccess.getGiveawayLeaderboard(
            219255,
            pageState.page,
            pageState.rowsPerPage,
            onSuccess(pageState.page)
        )
        // eslint-disable-next-line
    }, [])

    const handleChangePage = (event: unknown, newPage: number) => {
        GiveawayDataAccess.getGiveawayLeaderboard(219255, newPage, pageState.rowsPerPage, onSuccess(newPage))
    }

    const createAuthor = (user: MinimalUserTypeResponse) => (
        <Link
            className={styles.usersFullname}
            onClick={() => {
                window.open(composeUrl(getProfileURL(user)))
            }}
        >
            {user.profilePhoto ? (
                <Avatar src={user.profilePhoto} classes={avatarStyle} />
            ) : (
                <ProfileIcon classes={avatarStyle} />
            )}
            <span>{`${user.firstName} ${user.lastName}`}</span>
        </Link>
    )

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <div className={styles.titleContainer}>
                    <div>
                        <Typography classes={titleStyle}>{"Božićna darivalica"}</Typography>
                        <Typography classes={subtitleStyle}>
                            {"Učenici, osvojite prijenosne punjače za mobitel!"}
                        </Typography>
                    </div>
                    <img src={Illustration} alt="giveaway-ilustration" />
                </div>
                <Typography classes={textStyle}>
                    {
                        "Postavljaj pitanja iz školskog gradiva, sakupi najviše odgovora na njih i jedan od 30 prijenosnih punjača za mobitel je tvoj!"
                    }
                </Typography>
                <br />
                <Typography classes={textStyle}>
                    {"Darivanje traje od 9. do 22. prosinca. Pravila pronađi na "}
                    <a
                        href="https://www.srednja.hr/novosti/darivalica-carnet/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {"ovom linku."}
                    </a>
                </Typography>
                <br />
                <Typography classes={textStyle}>{"Top lista korisnika po broju odgovora:"}</Typography>
                <div className={styles.columnHeaderContainer}>
                    <Typography classes={columnHeaderStyle}>{"RANG:"}</Typography>
                    <Typography classes={columnHeaderStyle}>{"SKUPLJENI ODGOVORI:"}</Typography>
                </div>
            </div>
            {leaderboard != null && (
                <>
                    {leaderboard.items.map((item, index) => {
                        const position = index + 1 + pageState.page * pageState.rowsPerPage
                        return (
                            <div key={item.user.id} className={styles.listItemContainer}>
                                <div
                                    className={
                                        position === 1 ? styles.ordinalNumberFirstPlace : styles.ordinalNumberOthers
                                    }
                                >
                                    <Typography
                                        classes={position === 1 ? ordinalNumberFirstPlace : ordinalNumberOthers}
                                    >
                                        {position}
                                    </Typography>
                                </div>
                                {createAuthor(item.user)}
                                <Typography classes={valueTypoStyle}>{item.value}</Typography>
                            </div>
                        )
                    })}
                    {leaderboard.totalElements > GIVEAWAY_PAGESIZE && (
                        <PaginationComponent
                            rows={leaderboard.totalElements}
                            page={pageState.page}
                            rowsPerPage={pageState.rowsPerPage}
                            onChangePage={handleChangePage}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default withRouter(CarnetGiveaway)
