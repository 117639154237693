import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../../utils/FontWeights"
import Colors from "../../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "row",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column"
            }
        },
        dot: {
            height: 8,
            width: 8,
            backgroundColor: theme.palette.common.black,
            borderRadius: 4,
            marginTop: 14,
            marginLeft: 28,
            marginRight: 28,
            [theme.breakpoints.down("xs")]: {
                display: "none"
            }
        },
        textContainer: {
            [theme.breakpoints.down("xs")]: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 4,
                marginBottom: 4
            }
        }
    })
)

const topicTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "block",
            fontSize: 24,
            color: Colors.topicSubtopicInfoTopicFont,
            fontWeight: FontWeights.BOLD,
            lineHeight: 1.5,
            [theme.breakpoints.down("xs")]: {
                fontSize: 18,
                paddingRight: 8
            }
        }
    })
)

const subtopicTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "block",
            fontSize: 24,
            color: Colors.topicSubtopicInfoSubtopicFont,
            fontWeight: FontWeights.BOLD,
            lineHeight: 1.5,
            [theme.breakpoints.down("xs")]: {
                fontSize: 12,
                paddingRight: 8
            }
        }
    })
)

export default {
    styles,
    topicTypoStyle,
    subtopicTypoStyle
}
