import { MinimalUserTypeResponse } from "../types/UserTypes"
import { generatePath } from "react-router-dom"
import Endpoints from "../environments/endpoints"
import { MinimalQuestionTypeResponse, QuestionWithFollowersTypeResponse } from "../types/QuestionTypes"

const utmCode = "?utm_source=srednja-hr&utm_medium=embedded-widget"

export const getProfileURL = (user: MinimalUserTypeResponse): string => {
    const url = generatePath(Endpoints.appEndpoints.profile, {
        fullName: `${user.encodedFirstName}-${user.encodedLastName}`,
        id: user.id.toString()
    })
    return url + utmCode
}

export const getActiveQuestionURL = (question: QuestionWithFollowersTypeResponse): string => {
    const url = generatePath(Endpoints.appEndpoints.questionAnswering, {
        topicName: question.topic.encodedName,
        subtopicName: question.subtopic.encodedName,
        questionid: question.id.toString()
    })
    return url + utmCode
}

export const getQuestionURL = (question: MinimalQuestionTypeResponse): string => {
    const url = generatePath(Endpoints.appEndpoints.questionAnswering, {
        topicName: question.encodedTopicName,
        subtopicName: question.encodedSubtopicName,
        questionid: question.id.toString()
    })
    return url + utmCode
}
