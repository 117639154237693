import React, { useState, useEffect } from "react"
import { RouteComponentProps } from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import QuestionCard from "../common_components/QuestionCard/QuestionCard"
import HorizontalScroll from "../common_components/HorizontalScroll/HorizontalScroll"
import Styles from "./EmbeddedPopularQuestions.style"
import Loading from "../common_components/Loading/Loading"
import { MinimalQuestionTypeResponse } from "../../types/QuestionTypes"
import QuestionsDataAccess from "../../data_access/QuestionsDataAccess"

const shuffle = (array: MinimalQuestionTypeResponse[]) => {
    let j, x, i
    for (i = array.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1))
        x = array[i]
        array[i] = array[j]
        array[j] = x
    }
    return array
}

const EmbeddedPopularQuestions: React.FC<RouteComponentProps> = () => {
    const styles = Styles.styles({})
    const typoStyle = Styles.typoStyle({})

    const [questions, setQuestions] = useState<MinimalQuestionTypeResponse[]>([])

    const onLoadedQuestions = (loadedQuestions: MinimalQuestionTypeResponse[]) => {
        setQuestions(shuffle(loadedQuestions))
    }

    useEffect(() => {
        QuestionsDataAccess.getFewPopularQuestions(10, onLoadedQuestions)
        // eslint-disable-next-line
    }, [])

    const questionCards = questions.map((q, index) => <QuestionCard key={index} question={q} />)

    const horizontalScroll = <HorizontalScroll items={questionCards} whiteArrows={true} />
    const loading = (
        <div className={styles.loadingContainer}>
            <Loading white={true} />
        </div>
    )

    return (
        <div className={styles.container}>
            <Typography classes={typoStyle}>{"Najpopularnija pitanja na kvizovi.srednja.hr"}</Typography>
            {questionCards.length > 0 ? horizontalScroll : loading}
        </div>
    )
}

export default EmbeddedPopularQuestions
