const startingTag = "<mathlatex>"
const endingTag = "</mathlatex>"

export const wrapMathInputInTags = (input: string): string => {
    return `${startingTag}${input}${endingTag}`
}

export const checkIfMathExpression = (input: string): boolean => {
    const startingIndex = input.indexOf(startingTag)
    const endingIndex = input.indexOf(endingTag)
    return startingIndex > -1 && endingIndex > -1
}

export const unwrapMathInputFromTags = (input: string): string => {
    const startingIndex = input.indexOf(startingTag)
    const endingIndex = input.indexOf(endingTag)
    return input.substring(startingIndex + startingTag.length, endingIndex)
}
