import React from "react"
import { ThemeProvider } from "@material-ui/styles"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import EmbeddedQuestion from "./components/EmbeddedQuestion/EmbeddedQuestion"
import EmbeddedPopularQuestions from "./components/EmbeddedPopularQuestions/EmbeddedPopularQuestions"
import EmbeddedGiveaway from "./components/EmbeddedGiveaway/EmbeddedGiveaway"
import MaturantiGiveaway from "./components/MaturantiGiveaway/MaturantiGiveaway"
import CarnetGiveaway from "./components/CarnetGiveaway/CarnetGiveaway"
import EUPovijestGeografijaGiveaway from "./components/EUPovijestGeografijaGiveaway/EUPovijestGeografijaGiveaway"
import EUOkolisGiveaway from "./components/EUOkolisGiveaway/EUOkolisGiveaway"
import EUZnanostGiveaway from "./components/EUZnanostGiveaway/EUZnanostGiveaway"
import EUUnijaGiveaway from "./components/EUUnijaGiveaway/EUUnijaGiveaway"
import Theme from "./utils/MaterialUIThemeConfig"
import Endpoints from "./environments/endpoints"

const App: React.FC = () => {
    return (
        <ThemeProvider theme={Theme}>
            <BrowserRouter>
                <Switch>
                    <Route path={Endpoints.appEndpoints.embeddedQuestion} render={p => <EmbeddedQuestion {...p} />} />
                    <Route
                        path={Endpoints.appEndpoints.embeddedPopularQuestions}
                        render={p => <EmbeddedPopularQuestions {...p} />}
                    />
                    <Route path={Endpoints.appEndpoints.embeddedGiveaway} render={p => <EmbeddedGiveaway {...p} />} />
                    <Route
                        path={Endpoints.appEndpoints.embeddedGiveawayMaturanti}
                        render={p => <MaturantiGiveaway {...p} />}
                    />
                    <Route
                        path={Endpoints.appEndpoints.embeddedGiveawayCarnet}
                        render={p => <CarnetGiveaway {...p} />}
                    />
                    <Route
                        path={Endpoints.appEndpoints.embeddedGiveawayEUPovijestGeografija}
                        render={p => <EUPovijestGeografijaGiveaway {...p} />}
                    />
                    <Route
                        path={Endpoints.appEndpoints.embeddedGiveawayEUOkolis}
                        render={p => <EUOkolisGiveaway {...p} />}
                    />
                    <Route
                        path={Endpoints.appEndpoints.embeddedGiveawayEUZnanost}
                        render={p => <EUZnanostGiveaway {...p} />}
                    />
                    <Route
                        path={Endpoints.appEndpoints.embeddedGiveawayEUUnija}
                        render={p => <EUUnijaGiveaway {...p} />}
                    />
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default App
