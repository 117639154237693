import React from "react"
import Typography from "@material-ui/core/Typography"
import Styles from "./QuestionDifficulty.style"

interface QuestionDifficultyProps {
    difficulty: number
    numberOfAnswers: number
}

const MAX_QUESTION_DIFFICULTY = 10

const QuestionDifficulty: React.FC<QuestionDifficultyProps> = props => {
    const styles = Styles.styles({})
    const integerPartTypoStyle = Styles.integerPartTypoStyle({})
    const fractionalPartTypoStyle = Styles.fractionalPartTypoStyle({})
    const numberOfAnswersTypoStyle = Styles.numberOfAnswersTypoStyle({})

    const [integerPart, fractionalPart] = props.difficulty.toFixed(2).split(".")

    return (
        <div>
            <div className={styles.difficultyContainer}>
                <Typography classes={integerPartTypoStyle}>{integerPart}</Typography>
                <Typography classes={fractionalPartTypoStyle}>
                    {"." + fractionalPart + " / " + MAX_QUESTION_DIFFICULTY}
                </Typography>
            </div>
            <div className={styles.answersContainer}>
                <Typography classes={numberOfAnswersTypoStyle}>
                    {`na temelju ${props.numberOfAnswers} odgovora`}
                </Typography>
            </div>
        </div>
    )
}

export default QuestionDifficulty
