import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            marginTop: 16,
            marginBottom: 16,
            [theme.breakpoints.down("xs")]: {
                marginTop: "unset",
                marginBottom: "unset"
            }
        },
        answerContainer: {
            marginTop: 16,
            marginBottom: 16,
            [theme.breakpoints.down("xs")]: {
                marginTop: "unset",
                marginBottom: "unset"
            }
        }
    })
)

export default {
    styles
}
