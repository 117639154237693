import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../../utils/FontWeights"
import Colors from "../../../utils/Colors"

const containerStyle = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        }
    })
)

const iconStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 12,
            width: 12,
            marginRight: 4,
            color: Colors.likeInfoIcon
        }
    })
)

const typoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 2,
            fontSize: 14,
            fontWeight: FontWeights.BOLD
        }
    })
)

export default {
    containerStyle,
    iconStyle,
    typoStyle
}
