import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "fit-content",
            transition: "transform 300ms ease-in-out",
            paddingLeft: 52,
            paddingRight: 52,
            [theme.breakpoints.down("xs")]: {
                paddingLeft: "unset",
                paddingRight: "unset"
            }
        },
        scrollableContainer: {
            overflow: "auto",
            WebkitOverflowScrolling: "touch"
        },
        horizontalContainer: {
            display: "flex",
            flexDirection: "row"
        },
        arrowContainer: {
            textAlign: "center"
        }
    })
)

export default styles
