import React from "react"
import TablePagination from "@material-ui/core/TablePagination"
import { GIVEAWAY_PAGESIZE } from "../../EmbeddedGiveaway/EmbeddedGiveaway"

interface PaginationComponentsProps {
    rows: number
    page: number
    rowsPerPage: number
    onChangePage: (e: any, newPage: number) => void
}

const PaginationComponent: React.FC<PaginationComponentsProps> = props => {
    return (
        <TablePagination
            component="div"
            count={props.rows}
            rowsPerPageOptions={[GIVEAWAY_PAGESIZE]}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onChangePage={props.onChangePage}
        />
    )
}

export default PaginationComponent
