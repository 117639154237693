import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../../utils/FontWeights"
import Colors from "../../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        difficultyContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "baseline"
        },
        answersContainer: {
            display: "flex",
            justifyContent: "flex-end"
        }
    })
)

const integerPartTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 30,
            color: theme.palette.common.black,
            fontWeight: FontWeights.BOLD,
            [theme.breakpoints.down("xs")]: {
                fontSize: 24
            }
        }
    })
)

const fractionalPartTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            color: theme.palette.common.black,
            fontWeight: FontWeights.BOLD,
            [theme.breakpoints.down("xs")]: {
                fontSize: 12
            }
        }
    })
)

const numberOfAnswersTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 12,
            color: Colors.questionDifficultyNumberOfAnwersFont,
            fontStyle: "italic"
        }
    })
)

export default {
    styles,
    integerPartTypoStyle,
    fractionalPartTypoStyle,
    numberOfAnswersTypoStyle
}
