import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router"
import { QuestionWithFollowersTypeResponse } from "../../types/QuestionTypes"
import { AnswerProps, AnswerLetter } from "../../types/AnswerTypes"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import FollowButton from "../common_components/FollowButton/FollowButton"
import QuestionDifficulty from "../common_components/QuestionDifficulty/QuestionDifficulty"
import AnswersGrid from "../common_components/AnswersGrid/AnswersGrid"
import AnswersColumn from "../common_components/AnswersColumn/AnswersColumn"
import Styles from "./EmbeddedQuestion.style"
import Endpoints from "../../environments/endpoints"
import QuestionsDataAccess from "../../data_access/QuestionsDataAccess"
import EmbeddedTopicSubtopicInfo from "../common_components/EmbeddedTopicSubtopicInfo/EmbeddedTopicSubtopicInfo"
import { Avatar } from "@material-ui/core"
import ProfileIcon from "@material-ui/icons/AccountCircleRounded"
import Link from "@material-ui/core/Link"
import { addStyles, StaticMathField } from "react-mathquill"
import { checkIfMathExpression, unwrapMathInputFromTags } from "../../utils/MathLatexHandlers"
import { getActiveQuestionURL, getProfileURL } from "../../utils/BuildUrls"

addStyles()

const NUMBER_OF_CHARS_FOR_WRAP = 64
const ANSWER_LETTERS: AnswerLetter[] = ["A", "B", "C", "D"]

const EmbeddedQuestion: React.FC<RouteComponentProps> = props => {
    const styles = Styles.styles({})
    const paperStyle = Styles.paperStyle({})
    const authorTypoStyle = Styles.authorTypoStyle({})
    const questionTypoStyle = Styles.questionTypoStyle({})
    const avatarStyle = Styles.avatarStyle({})

    const [question, setQuestion] = useState<QuestionWithFollowersTypeResponse | undefined>(undefined)
    const [answered] = useState<AnswerLetter | undefined>(undefined)

    const composeUrl = (url: string) => Endpoints.appEndpoint + url

    const onSuccess = (question: QuestionWithFollowersTypeResponse) => {
        setQuestion(question)
    }

    useEffect(() => {
        if (props.location && props.location.pathname && props.location.pathname.startsWith("/embeddedquestion")) {
            const questionId = props.location.pathname.split("/embeddedquestion/")[1]
            QuestionsDataAccess.getQuestionByIdForEmbeddedWidget(questionId, onSuccess)
        }
        // eslint-disable-next-line
    }, [props.location])

    const answers: AnswerProps[] =
        question != null
            ? question.answers.map((answer, index) => {
                  return { answerLetter: ANSWER_LETTERS[index], answer: answer }
              })
            : []

    const requiresWrapping = (answer: AnswerProps) => answer.answer.length > NUMBER_OF_CHARS_FOR_WRAP

    const redirect = () => {
        if (question != null) {
            window.open(
                composeUrl(getActiveQuestionURL(question))
            )
        }
    }

    const onAnswer = (answerLetter: AnswerLetter) => redirect()

    const calculateAnswersLayout = (answers: AnswerProps[]) => {
        if (answers.some(requiresWrapping)) {
            return (
                <AnswersColumn
                    answers={answers}
                    correctAnswer={question!.correctAnswer}
                    clickedAnswer={answered}
                    onAnswer={onAnswer}
                />
            )
        }
        return (
            <AnswersGrid
                answers={answers}
                correctAnswer={question!.correctAnswer}
                type="text"
                clickedAnswer={answered}
                onAnswer={onAnswer}
            />
        )
    }

    const followUser = () => redirect()

    const authorWithLink = question != null && question.author && (
        <Link
            className={authorTypoStyle.root}
            onClick={() => {
                if (question != null && question.author != null) {
                    window.open(composeUrl(getProfileURL(question.author)))
                }
            }}
        >
            {question.author.profilePhoto ? (
                <Avatar src={question.author.profilePhoto} classes={avatarStyle} />
            ) : (
                <ProfileIcon classes={avatarStyle} />
            )}
            <span>{`${question.author.firstName} ${question.author.lastName}`}</span>
        </Link>
    )

    const author = (
        <div className={styles.authorContainer}>
            {question != null && question.author != null && (
                <>
                    {authorWithLink}
                    <FollowButton
                        following={false}
                        numberOfFollowers={question.author.numberOfFollowers}
                        onFollow={followUser}
                        onSeeFollowers={followUser}
                    />
                </>
            )}
        </div>
    )

    return question != null ? (
        <>
            <Paper classes={paperStyle}>
                <div>
                    <EmbeddedTopicSubtopicInfo topic={question.topic} subtopic={question.subtopic} />
                </div>
                <div className={styles.headerContainer}>
                    {author}
                    <QuestionDifficulty difficulty={question.difficulty} numberOfAnswers={question.numberOfAnswers} />
                </div>
                {checkIfMathExpression(question.questionText) ? (
                    <div className={styles.mathQuestionContainer}>
                        <StaticMathField>{unwrapMathInputFromTags(question.questionText)}</StaticMathField>
                    </div>
                ) : (
                    <Typography classes={questionTypoStyle}>{question.questionText}</Typography>
                )}
                {question.questionImage != null && (
                    <img src={question.questionImage} className={styles.questionImage} alt="question" />
                )}
                {question.imageAnswers ? (
                    <AnswersGrid
                        answers={answers}
                        correctAnswer={question.correctAnswer}
                        type="image"
                        clickedAnswer={answered}
                        onAnswer={onAnswer}
                    />
                ) : (
                    calculateAnswersLayout(answers)
                )}
            </Paper>
        </>
    ) : null
}

export default withRouter(EmbeddedQuestion)
