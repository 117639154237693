import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../../utils/FontWeights"
import Colors from "../../../utils/Colors"

const cardStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 220,
            width: 364,
            margin: 12,
            [theme.breakpoints.down("xs")]: {
                width: 296
            }
        }
    })
)

const actionAreaStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            whiteSpace: "normal"
        }
    })
)

const contentStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }
    })
)

const styles = makeStyles((theme: Theme) =>
    createStyles({
        authorAndTopicContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
        topicSubtopic: {
            display: "flex",
            alignItems: "center",
            marginTop: 4
        },
        dotDelimiter: {
            height: 4,
            width: 4,
            borderRadius: 2,
            margin: 4,
            backgroundColor: theme.palette.primary.main
        },
        questionContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginTop: 12
        },
        narrowQuestionImage: {
            objectFit: "cover",
            height: 70,
            width: 70,
            marginRight: 12
        },
        wideQuestionImage: {
            objectFit: "cover",
            height: 70,
            width: "100%",
            marginRight: 12
        },
        footerContainer: {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between"
        },
        likeButton: {
            paddingBottom: 12
        },
        icon: {
            margin: "8px -16px -8px 16px"
        },
        doneIconContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 32,
            height: 32,
            marginBottom: 3,
            borderRadius: "50%",
            border: `1px solid ${Colors.questionCardCheckmarkBorder}`
        },
        mathExpression: {
            width: "100%",
            overflow: "hidden",
            color: Colors.questionCardQuestionFont
        }
    })
)

const authorTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 14,
            color: Colors.questionCardAuthorFont
        }
    })
)

const topicSubtopicTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 14,
            color: theme.palette.primary.main
        }
    })
)

const questionTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 70,
            fontSize: 16,
            fontWeight: FontWeights.BOLD,
            color: Colors.questionCardQuestionFont
        }
    })
)

export default {
    cardStyle,
    actionAreaStyle,
    contentStyle,
    styles,
    authorTypoStyle,
    topicSubtopicTypoStyle,
    questionTypoStyle
}
