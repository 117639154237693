import React from "react"
import { MinimalQuestionTypeResponse } from "../../../types/QuestionTypes"
import { withRouter, RouteComponentProps } from "react-router"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import LikeInfo from "../LikeInfo/LikeInfo"
import CardIcon from "../../../images/card-icon.svg"
import Endpoints from "../../../environments/endpoints"
import Styles from "./QuestionCard.style"
import { addStyles, StaticMathField } from "react-mathquill"
import { checkIfMathExpression, unwrapMathInputFromTags } from "../../../utils/MathLatexHandlers"
import { getQuestionURL } from "../../../utils/BuildUrls"

addStyles()

interface QuestionCardProps extends RouteComponentProps {
    question: MinimalQuestionTypeResponse
}

const DEFAULT_USER = "Srednja.hr"
const NUMBER_OF_CHARS = 96
const NUMBER_OF_CHARS_WITH_IMAGE = 64

const QuestionCard: React.FC<QuestionCardProps> = props => {
    const cardStyle = Styles.cardStyle({})
    const actionAreaStyle = Styles.actionAreaStyle({})
    const contentStyle = Styles.contentStyle({})
    const styles = Styles.styles({})
    const authorTypoStyle = Styles.authorTypoStyle({})
    const topicSubtopicTypoStyle = Styles.topicSubtopicTypoStyle({})
    const questionTypoStyle = Styles.questionTypoStyle({})

    const renderImage = (imagePath: string | null, narrow: boolean) => (
        <img
            className={narrow ? styles.narrowQuestionImage : styles.wideQuestionImage}
            alt="question"
            src={imagePath != null ? imagePath : undefined}
        />
    )

    const renderText = (questionText: string, numberOfChars: number) => (
        <Typography classes={questionTypoStyle}>{cropText(questionText, numberOfChars)}</Typography>
    )

    const renderMathExpression = (questionText: string) => (
        <div className={styles.mathExpression}>
            <StaticMathField>{unwrapMathInputFromTags(questionText)}</StaticMathField>
        </div>
    )

    const renderImageAndText = (
        <>
            {renderImage(props.question.questionImage, true)}
            {renderText(props.question.questionText, NUMBER_OF_CHARS_WITH_IMAGE)}
        </>
    )

    const author = (
        <Typography classes={authorTypoStyle} noWrap>
            {props.question.author != null
                ? `${props.question.author.firstName} ${props.question.author.lastName}`
                : DEFAULT_USER}
        </Typography>
    )

    const goToQuestionAnswering = () => {
        const localQuestionUrl = getQuestionURL(props.question)
        window.open(Endpoints.appEndpoint + localQuestionUrl)
    }

    return (
        <Card classes={cardStyle}>
            <CardActionArea classes={actionAreaStyle} onClick={goToQuestionAnswering}>
                <CardContent classes={contentStyle}>
                    <div className={styles.authorAndTopicContainer}>
                        <div>{author}</div>
                        <div className={styles.topicSubtopic}>
                            <Typography
                                classes={topicSubtopicTypoStyle}
                                noWrap
                            >{`#${props.question.topicName}`}</Typography>
                            <div className={styles.dotDelimiter} />
                            <Typography
                                classes={topicSubtopicTypoStyle}
                                noWrap
                            >{`#${props.question.subtopicName}`}</Typography>
                        </div>
                    </div>
                    <div className={styles.questionContainer}>
                        {props.question.questionImage != null
                            ? props.question.questionText.length
                                ? renderImageAndText
                                : renderImage(props.question.questionImage, false)
                            : checkIfMathExpression(props.question.questionText)
                            ? renderMathExpression(props.question.questionText)
                            : renderText(props.question.questionText, NUMBER_OF_CHARS)}
                    </div>
                    <div className={styles.footerContainer}>
                        <div className={styles.likeButton}>
                            <LikeInfo numberOfLikes={props.question.likeCount} />
                        </div>
                        <img className={styles.icon} alt="card-icon" src={CardIcon} />
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

const cropText = (text: string, size: number) => {
    return text.substring(0, size) + "..."
}

const areEqual = (prevProps: QuestionCardProps, nextProps: QuestionCardProps) => {
    return prevProps.question.id === nextProps.question.id
}

export default withRouter(React.memo(QuestionCard, areEqual))
