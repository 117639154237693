import ColorPalette from "./ColorPalette"

const Colors = {
    white: ColorPalette.WHITE,
    primary: ColorPalette.BLUE1,
    secondary: ColorPalette.ORANGE1,
    arrowDisabled: ColorPalette.GREY1,
    likeInfoIcon: ColorPalette.YELLOW2,
    questionCardAuthorFont: ColorPalette.GREY2,
    questionCardQuestionFont: ColorPalette.BLACK,
    questionCardAnsweredQuestion: ColorPalette.GREY1,
    questionCardCheckmark: ColorPalette.YELLOW2,
    questionCardCheckmarkBorder: ColorPalette.YELLOW5,
    answerLetter: ColorPalette.BLACK,
    answerText: ColorPalette.GREY3,
    answerCorrect: ColorPalette.GREEN1,
    answerWrong: ColorPalette.RED1,
    answerNeutralBorder: ColorPalette.GREY6,
    questionAuthorFont: ColorPalette.GREY3,
    questionQuestionFont: ColorPalette.BLACK,
    questionDifficultyNumberOfAnwersFont: ColorPalette.GREY3,
    topicSubtopicInfoTopicFont: ColorPalette.BLACK,
    topicSubtopicInfoSubtopicFont: ColorPalette.GREY3
}

export default Colors
