import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column"
        },
        row: {
            display: "flex",
            flexDirection: "row",
            marginTop: 16,
            marginBottom: 16,
            [theme.breakpoints.down("xs")]: {
                marginTop: "unset",
                marginBottom: "unset",
                flexDirection: "column"
            }
        },
        leftAnswerContainer: {
            width: "50%",
            paddingRight: 12,
            [theme.breakpoints.down("xs")]: {
                width: "unset",
                paddingRight: "unset"
            }
        },
        rightAnswerContainer: {
            width: "50%",
            paddingLeft: 12,
            [theme.breakpoints.down("xs")]: {
                width: "unset",
                paddingLeft: "unset"
            }
        }
    })
)

export default {
    styles
}
