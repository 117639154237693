const ColorPalette = {
    BLACK: "#34383f",
    WHITE: "#ffffff",
    BLUE1: "#67a0f1",
    BLUE2: "#90beff",
    BLUE3: "#bdd9ff",
    BLUE4: "#d6e2f3",
    BLUE5: "#4267b2",
    BLUE6: "#b1d1ff",
    BLUE7: "#c7deff",
    ORANGE1: "#ff911f",
    ORANGE2: "#ffbf75",
    YELLOW1: "#ffecb0",
    YELLOW2: "#fdca27",
    YELLOW3: "#ddab0d",
    YELLOW4: "#ffe07f",
    YELLOW5: "#fee9a8",
    GREY1: "#d8d8d8",
    GREY2: "#455a64",
    GREY3: "#6f7786",
    GREY4: "#fafafa",
    GREY5: "#6c6c6c",
    GREY6: "#d3d3d3",
    GREY7: "#4e4e4e",
    GREY8: "#dbdbdb",
    RED1: "#fc633e",
    RED2: "#f17252",
    RED3: "#b00020",
    GREEN1: "#68dd42",
    GREEN2: "#76c95c",
    GREEN3: "#d6ec6a"
}

export default ColorPalette
