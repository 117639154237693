import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../../utils/FontWeights"

const styles = makeStyles((theme: Theme) => {
    return createStyles({
        container: {
            display: "flex",
            flexDirection: "row"
        }
    })
})

const buttonStyle = (fullWidth?: boolean) => {
    return makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                height: 24,
                width: fullWidth ? "100%" : "fit-content",
                textTransform: "none"
            },
            label: {
                margin: "-4px 8px"
            }
        })
    })
}

const numberButtonStyle = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            height: 24,
            minWidth: "unset",
            marginLeft: 2,
            padding: 0,
            textTransform: "none"
        },
        label: {
            margin: "-4px 6px"
        }
    })
})

const iconStyle = (following: boolean) => {
    return makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                height: 14,
                color: following ? theme.palette.common.white : "unset"
            }
        })
    })
}

const typoStyle = (following: boolean) => {
    return makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                marginTop: 2,
                fontSize: 12,
                color: following ? theme.palette.common.white : "unset"
            }
        })
    })
}

const numberTypoStyle = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            marginTop: 2,
            fontSize: 12,
            fontWeight: FontWeights.BOLD
        }
    })
})

export default {
    styles,
    buttonStyle,
    numberButtonStyle,
    iconStyle,
    typoStyle,
    numberTypoStyle
}
