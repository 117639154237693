import axios from "axios"
import Endpoints from "../environments/endpoints"
import { QuestionWithFollowersTypeResponse, MinimalQuestionTypeResponse } from "../types/QuestionTypes"

const getQuestionByIdForEmbeddedWidget = (
    questionId: string,
    onSuccess: (question: QuestionWithFollowersTypeResponse) => void
) => {
    axios
        .get(`${Endpoints.apiEndpoint}/questions/${questionId}`)
        .then(response => {
            let question: QuestionWithFollowersTypeResponse = response.data
            onSuccess(question)
        })
        .catch(error => {})
}

const getFewPopularQuestions = (
    numberOfQuestions: number,
    onSuccess: (questions: MinimalQuestionTypeResponse[]) => void
) => {
    axios
        .get(`${Endpoints.apiEndpoint}/questions/popular?userCount=${numberOfQuestions}`)
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

export default {
    getQuestionByIdForEmbeddedWidget,
    getFewPopularQuestions
}
